<template>
  <div>
    <loading
      transition="fade"
      :active.sync="waitTimeout"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      color="#efb639"
      :opacity="1"
    >
    </loading>
    <v-btn href="/services/products" elevation="0" color="transparent">
      <v-icon left> mdi-chevron-left </v-icon>
      <span>Mes produits</span>
    </v-btn>
    <v-card outlined class="my-5 mt-10">
      <v-card-text class="profilAgencyAvatar">
        <v-card max-width="fit-content" color="EoleBlue" class="rounded-pill pr-2" flat>
          <v-card-title style="color: white !important; display: inline-block">
            <i :class="[`fa-solid fa-${product.icon}`, ' mr-2 ml-2']" style="font-size: 1.75rem"></i>
            {{ product.name }}
          </v-card-title>
        </v-card>
      </v-card-text>
      <v-card-text class="mt-16">
        <v-row>
          <v-col cols="12" md="12" class="text-left">
            <v-card flat>
              <v-textarea
                v-model="product.description"
                outlined
                name="input-7-4"
                label="Informations produit"
                auto-grow
              ></v-textarea>
              <v-card-actions class="no-padding justify-end mt-n2 mb-n4">
                <v-btn color="EoleYellow" class="white--text" outlined @click="putProductDescription"
                  >Sauvegarder</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="text-right"> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-tabs class="text-left" v-model="tab" style="box-shadow: none !important" background-color="transparent">
      <v-tab href="#tab-1" v-if="product.particular === 1"> Particulier </v-tab>
      <v-tab href="#tab-2" v-if="product.professionnal === 1"> Professionnel </v-tab>
    </v-tabs>
    <v-divider class="mb-5"></v-divider>
    <v-card-title class="no-padding mb-3 mt-10">Nos assureurs sur ce risque</v-card-title>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text v-if="product && product.insurers && product.insurers.length > 0">
            <v-row>
              <v-col
                class="d-flex text-center"
                v-for="(insurer, index) in product.insurers"
                :key="insurer.id"
                cols="12"
                md="2"
              >
                <v-card
                  height="250"
                  :title="`${insurer.name}`"
                  outlined
                  target="_blank"
                  class="text-center justify-center flex d-flex flex-column"
                >
                  <v-card-text>{{ insurer.name }}</v-card-text>
                  <v-card-text class="flex-grow-1">
                    <v-img
                      alt="test"
                      class="mx-auto my-2"
                      max-height="80"
                      max-width="80"
                      :src="`https://logo.clearbit.com/https://${insurer.website_url}?size=128`"
                    ></v-img>
                  </v-card-text>
                  <v-card-actions class="justify-center mb-2">
                    <v-dialog
                      v-model="insurer.dialogUpload"
                      max-width="1200px"
                      @click:outside="
                        document = {
                          name: '',
                          data: '',
                        }
                        fileUrl = null
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn text large v-bind="attrs" v-on="on"><i class="fa-solid fa-file-pdf fa-2x"></i>FDR</v-btn>
                      </template>

                      <v-card flat>
                        <v-card-title>
                          <span class="text-h5">Formulaire de déclaration de risques {{ insurer.name }}</span>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text class="pa-5">
                          <v-row>
                            <v-col cols="12" sm="6" md="12">
                              <v-card flat class="text-center align-center justify-center">
                                <v-row class="pa-5">
                                  <v-col cols="12" md="12">
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        md="6"
                                        v-for="(document, indexDocument) in insurer.documents"
                                        v-bind:key="indexDocument"
                                      >
                                        <div>
                                          {{ document.name }} - {{ document.date }}
                                          <span v-if="document === null && fileUrl === null"
                                            >Aucun document n'est pour le moment disponible.</span
                                          >
                                          <iframe
                                            v-else
                                            id="lddcVisualisator"
                                            style="width: 100%; height: 500px"
                                            :src="`${document.url}?cache=${Date.now()}`"
                                          ></iframe>
                                        </div>

                                        <v-card-text v-if="document.url != null">
                                          <v-row align="center">
                                            <v-col cols="12" md="6">
                                              <v-btn
                                                color="EoleError"
                                                class="white--text"
                                                @click="deleteFile(insurer, indexDocument)"
                                              >
                                                supprimer
                                              </v-btn></v-col
                                            >
                                            <v-col cols="12" md="6">
                                              <v-btn
                                                color="EoleBlue"
                                                class="white--text"
                                                @click="downloadFile(`${document.url}?cache=${Date.now()}`)"
                                              >
                                                Télécharger
                                              </v-btn></v-col
                                            >
                                          </v-row>
                                        </v-card-text>
                                      </v-col>

                                      <v-col cols="12" md="6" class="d-flex">
                                        <v-card
                                          @click="openFileExplorer(index)"
                                          link
                                          :class="{ 'file-selected': fileSelected }"
                                          outlined
                                          @dragover.prevent
                                          v-model="document.data"
                                          @drop.prevent="onDrop($event, document.id, document.name)"
                                          class="drop-zone flex d-flex flex-column justify-center text-center"
                                        >
                                          <v-card-text>
                                            <v-icon size="64" color="EoleBlue">mdi-plus-circle</v-icon>
                                          </v-card-text>
                                          <v-card-text
                                            >Ajout d'un formulaire de déclaration de risques pour la compagnie
                                            {{ insurer.name }}</v-card-text
                                          >
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="EoleYellow"
                            text
                            @click="
                              () => {
                                insurer.dialogUpload = false
                                document = {
                                  name: '',
                                  data: '',
                                }
                              }
                            "
                          >
                            Annuler
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="insurer.dialogValidation" max-width="400">
                      <v-card>
                        <v-card-title>Voulez-vous envoyer ce document ?</v-card-title>
                        <v-card-actions>
                          <v-btn class="white--text" color="EoleError" @click="insurer.dialogValidation = false"
                            >Non</v-btn
                          >
                          <v-spacer></v-spacer>
                          <v-btn class="white--text" color="EoleGreen" @click="upload(insurer, index)">Oui</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else> Il n'y a aucun assureur pour ce produit </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-tabs-items v-model="tab" style="background-color: transparent !important">
      <v-tab-item :value="'tab-1'" v-if="product.particular === 1">
        <v-row class="mb-5">
          <v-col cols="12" md="12" class="text-left">
            <v-card-title class="no-padding mt-7 mb-2">
              Les points d'attention
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right>mdi-information</v-icon>
                </template>
                <span>Les points d'attention ....</span>
              </v-tooltip>
            </v-card-title>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Les points de vigilance"
                      auto-grow
                      v-model="product.particular_description"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"> </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn color="EoleYellow" class="white--text" outlined @click="putProductParticularDescription"
                          >Sauvegarder</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-title class="no-padding mb-3">Comparatif entre assureurs </v-card-title>

        <v-simple-table
          v-if="product.insurers.length > 0 && product.particular_informations != null"
          class="company-table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">FOCUS PAR COMPAGNIE</th>
                <th v-for="company in product.insurers" v-bind:key="company.name" class="text-center">
                  {{ company.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(guarantee, index) in product.particular_informations" v-bind:key="index">
                <td>
                  {{ guarantee.name }}
                </td>
                <td
                  v-for="(value, valueIndex) in guarantee.valueInsurer"
                  :key="valueIndex"
                  class="justify-center text-center"
                >
                  <v-edit-dialog
                    class="edit-dialog"
                    :return-value="value"
                    @input="updateValue(guaranteeIndex, valueIndex, $event)"
                  >
                    <v-text-field class="mt-5 hovered-input" :value="value" outlined dense></v-text-field>
                    <template v-slot:input>
                      <div class="edit-dialog">
                        <v-text-field
                          outlined
                          class="my-5"
                          dense
                          v-model="guarantee.valueInsurer[valueIndex]"
                          label="Nom de la garantie"
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>
          Aucune données n'est accessible. Aucun assureur n'assure ce risque ou aucune données n'est
          rentrée.</v-card-text
        >
        <v-card-actions class="no-padding mt-5">
          <v-spacer></v-spacer>

          <v-btn
            v-if="product.insurers.length > 0 && product.particular_informations != null"
            color="EoleGreen"
            class="white--text"
            @click="putInformations(product.id, product.particular_informations, 'particular')"
            >Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-tab-item>
      <v-tab-item :value="'tab-2'" v-if="product.professionnal === 1">
        <v-row class="mb-5">
          <v-col cols="12" md="12" class="text-left">
            <v-card-title class="no-padding mt-7 mb-2">
              Les points d'attention
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" right>mdi-information</v-icon>
                </template>
                <span>Les points d'attention ....</span>
              </v-tooltip>
            </v-card-title>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Les points de vigilance"
                      auto-grow
                      v-model="product.professionnal_description"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions class="mt-n5">
                    <v-row>
                      <v-col cols="12" md="6" class="text-left"> </v-col>
                      <v-col cols="12" md="6" class="text-right">
                        <v-btn
                          color="EoleYellow"
                          class="white--text"
                          outlined
                          @click="putProductProfessionnalDescription"
                          >Sauvegarder</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-title class="no-padding mb-3">Comparatif entre assureurs</v-card-title>
        <v-simple-table
          class="company-table"
          v-if="product.insurers.length > 0 && product.professionnal_informations != null"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">FOCUS PAR COMPAGNIE</th>
                <th class="text-center" v-for="company in product.insurers" v-bind:key="company.name">
                  {{ company.name }} <br />
                  Modifié le :
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(guarantee, index) in product.professionnal_informations" v-bind:key="index">
                <td>
                  {{ guarantee.name }}
                </td>
                <td
                  v-for="(value, valueIndex) in guarantee.valueInsurer"
                  :key="valueIndex"
                  class="justify-center text-center"
                >
                  <v-edit-dialog
                    class="edit-dialog background-transparent"
                    :return-value="value"
                    @input="updateValue(guaranteeIndex, valueIndex, $event)"
                  >
                    <v-text-field
                      v-if="value.length < 15"
                      class="mt-5 hovered-input"
                      :value="value"
                      outlined
                      dense
                    ></v-text-field>
                    <v-textarea v-else class="mt-5 hovered-input" :value="value" outlined dense></v-textarea>
                    <template v-slot:input>
                      <div class="edit-dialog">
                        <v-text-field
                          outlined
                          class="my-5"
                          dense
                          v-model="guarantee.valueInsurer[valueIndex]"
                          label="Nom de la garantie"
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-text v-else>
          Aucune données n'est accessible. Aucun assureur n'assure ce risque ou aucune données n'est
          rentrée.</v-card-text
        >
        <v-card-actions class="no-padding mt-5">
          <v-spacer></v-spacer>
          <v-btn
            v-if="product.insurers.length > 0 && product.professionnal_informations != null"
            color="EoleGreen"
            class="white--text"
            @click="putInformations(product.id, product.professionnal_informations, 'professionnal')"
            >Sauvegarder</v-btn
          >
        </v-card-actions>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import VueTree from "@ssthouse/vue-tree-chart"
Vue.component("vue-tree", VueTree)

import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"
import appbar from "@/layouts/Content.vue"
import emailjs from "@emailjs/browser"
import { init } from "@emailjs/browser"
init("user_gUunL4KW2ImPaGngWHYhP")
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Toast, {
  maxToasts: 2,
  newestOnTop: true,
})

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  data: () => ({
    product: {},
    waitTimeout: false,
    tab: "tab-2",
    document: {
      name: "",
      data: "",
    },
    fileSelected: true,
    dialogUpload: [],
    dialogValidation: [],
    fileUrl: null,
  }),

  async created() {
    this.agencyName = localStorage.getItem("agency")
    const urlParams = new URLSearchParams(window.location.search)
    const idProduct = urlParams.get("id")

    this.fetchProductById(idProduct).then(() => {
      document.title = `Mes Services Assurance - Produit ${this.product.name}`
    })
  },

  components: {
    appbar,
    Loading,
  },
  methods: {
    downloadFile2(url) {
      //create blob document and download it
      const link = document.createElement("a")
    },
    downloadFile(url) {
      //create blob document and download it

      const link = document.createElement("a")
      link.href = url
      link.target = "_blank"
      link.download = "Formulaire.pdf" // Remplacez 'nom-du-document' par le nom souhaité pour le fichier téléchargé
      link.click()
    },

    async putProductDescription() {
      let idAgency = localStorage.getItem("agency_id")

      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.product.id
          }&description=${encodeURIComponent(this.product.description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.fetchProductById(this.product.id)

          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putProductProfessionnalDescription() {
      let idAgency = localStorage.getItem("agency_id")
      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.product.id
          }&professionnalDescription=${encodeURIComponent(this.product.professionnal_description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.fetchProductById(this.product.id)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async putProductParticularDescription() {
      let idAgency = localStorage.getItem("agency_id")
      try {
        let response
        response = await fetch(
          `${config.apiUri}/php/pages/products.php?idAgency=${idAgency}&id=${
            this.product.id
          }&particularDescription=${encodeURIComponent(this.product.particular_description)}`,
          {
            mode: "cors",
            method: "PUT",
          },
        ).finally(() => {
          this.$toast.success(`Vous avez modifié le produit`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.fetchProductById(this.product.id)
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de modifier le texte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async deleteFile(insurer, indexDocument) {
      insurer.documents.splice(indexDocument, 1)

      const formData = new FormData()
      formData.append("place", "deleteProduct")
      formData.append("idAgencyProduct", this.product.id)
      formData.append("idAgencyPartner", insurer.id)
      formData.append("data", JSON.stringify(insurer.documents))
      formData.append("indexDelete", indexDocument)

      try {
        const res = await fetch(`${config.apiUri}/upload`, {
          method: "POST",
          body: formData,
        })
        if (res.ok) {
        } else {
          alert("Erreur lors de l'upload du PDF")
        }
      } catch (err) {
        console.error(err)
        alert("Erreur lors de l'upload du PDF")
      }
    },
    async upload(insurer, index) {
      console.log(this.document.data)

      this.product.insurers[index].dialogValidation = false
      let agencyName = localStorage.getItem("agency").toLowerCase().replace(" ", "_")

      insurer.documents.push({
        url: `${config.ged}/${agencyName}/products/${this.product.name.toLowerCase().replace(" ", "_")}/${
          this.document.data.name
        }`,
        name: this.document.data.name,
        type: this.document.data.type,
        size: this.document.data.size,
        date: new Date().toLocaleDateString(),
      })
      let documentName = this.document.data.name
      console.log(insurer.documents)

      let extension = this.getExtension(this.document.data.type)

      switch (extension) {
        case "pdf":
          // this.document.name =
          //   "formulaire_déclaration_risque_" + this.product.name.replace(/ /g, "_").toLowerCase() + "." + extension
          this.fileUrl = URL.createObjectURL(this.document.data)

          let headers = new Headers()
          headers.append("Access-Control-Allow-Origin", "*")

          const formData = new FormData()
          formData.append("product", this.product.name.toLowerCase().replace(" ", "_"))
          formData.append("agencyName", agencyName)
          formData.append("documentName", documentName)
          formData.append("company", insurer.name.toLowerCase().replace(" ", "_"))
          formData.append("place", "addProduct")
          formData.append("idAgencyProduct", this.product.id)
          formData.append("idAgencyPartner", insurer.id)
          formData.append("document", this.document.data)
          formData.append("data", JSON.stringify(insurer.documents))

          try {
            const res = await fetch(`${config.apiUri}/upload`, {
              method: "POST",
              body: formData,
            })
            if (res.ok) {
            } else {
              alert("Erreur lors de l'upload du PDF")
            }
          } catch (err) {
            console.error(err)
            alert("Erreur lors de l'upload du PDF")
          }
          break
        default:
          this.$toast.error(`Le format du fichier n'est pas valide\nLe type de fichier accepté est pdf`, {
            position: "bottom-right",
            timeout: 3000,
          })
          return
      }

      this.fileUrl = ""
    },
    onImgError() {
      this.imgError = true
    },
    openFileExplorer(index) {
      const input = document.createElement("input")

      input.type = "file"
      // only word docx pdf
      input.accept = ".pdf"
      input.multiple = true
      input.addEventListener("change", event => {
        this.document.data = event.target.files[0]
        this.product.insurers[index].dialogValidation = true
        console.log("ouverture")

        // for (let i = 0; i < event.target.files.length; i++) {
        //   const documente = event.target.files[i]
        //   const iframe = document.createElement("iframe")
        //   // iframe width
        //   iframe.width = "100%"
        //   iframe.src = URL.createObjectURL(documente)
        //   document.getElementById(`${insurer.name}`).appendChild(iframe)
        // }
      })
      input.click()
    },
    replaceSpacesWithUnderscores() {
      this.document.name = this.document.name.replace(/ /g, "_")
    },

    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes"

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    getExtension(fileType) {
      let extension = fileType.split("/")[1]
      return extension
    },
    onDrop(e) {
      e.preventDefault()
      this.document.data = e.dataTransfer.files[0]
      let realSize = this.formatBytes(this.document.data.size)

      if (this.document.data.size > 1000000) {
        this.$toast.error(
          `Votre fichier est trop volumineux (${realSize}). \n Veuillez choisir un fichier de moins de 1 Mo.`,
          {
            position: "bottom-right",
            timeout: 3000,
          },
        )
        this.document = {
          name: null,
          data: null,
          categorie: null,
        }
      } else {
        switch (this.document.data.type) {
          case "application/pdf":
            this.fileUrl = URL.createObjectURL(this.document.data)
            break
          default:
            this.fileSelected = false
            this.$toast.error(`Votre fichier n'est pas au bon format. \n Veuillez choisir un fichier au format .pdf`, {
              position: "bottom-right",
              timeout: 3000,
            })
        }
      }
    },
    async putInformations(idProduct, data, typology) {
      let idAgency = localStorage.getItem("agency_id")
      let formData = new FormData()
      formData.append("idProduct", idProduct)
      formData.append("idAgency", idAgency)
      formData.append("informations", JSON.stringify(data))
      typology === "particular"
        ? formData.append("typology", "particular")
        : formData.append("typology", "professionnal")

      try {
        fetch(`${config.apiUri}/agencies/${idAgency}/products/${idProduct}`, {
          mode: "cors",
          method: "POST",
          body: formData,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`Le produit à été modifié.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })

        this.fetchProductById(idProduct)

        this.$forceUpdate()
      } catch (e) {}
    },
    updateValue(guaranteeIndex, valueIndex, newValue) {
      this.item.guarantees[guaranteeIndex].valueInsurer[valueIndex] = newValue
    },
    addLine(array, insurerLength) {
      let valueInsurer = []
      for (let i = 0; i < insurerLength; i++) {
        valueInsurer.push("")
      }

      array.push({
        name: "",
        valueInsurer: valueInsurer,
      })
    },

    async fetchProductById(idProduct) {
      this.waitTimeout = true
      this.productsSponsoring = []
      this.loading = true
      let agencyId = localStorage.getItem("agency_id")

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/agencies/${agencyId}/products/${idProduct}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        if (data.code === 1) {
          data.product.forEach(async element => {
            element.insurers.forEach(insurer => {
              insurer.dialogUpload = false
              insurer.dialogValidation = false
              insurer.pdfUrl != null ? (insurer.pdfUrl = `${insurer.pdfUrl}?cache=${Date.now()}`) : null
              insurer.documents != "" && insurer.documents != null
                ? (insurer.documents = JSON.parse(insurer.documents))
                : (insurer.documents = [])
            })
            this.product = {
              id: element.id,
              icon: element.icon,
              name: element.name,
              description: element.description,
              insurers: element.insurers,
              particular: parseInt(element.particular),
              professionnal: parseInt(element.professionnal),
              professionnal_informations: JSON.parse(element.professionnal_informations),
              particular_informations: JSON.parse(element.particular_informations),
              description: element.description,
              particular_description: element.particular_description,
              professionnal_description: element.professionnal_description,
            }

            parseInt(element.particular) === 1 ? (this.tab = "tab-1") : (this.tab = "tab-2")
          })
          console.log("product =)", this.product)
        } else {
          this.$router.push("error-404")
        }
        this.waitTimeout = false
      } catch (e) {
        console.error(e)
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
  },
}
</script>

<style>
.theme--light.company-table th {
  background-color: #001f47 !important;
  font-size: 14px !important;
  color: #fcc03c !important;
}

.title {
  font-size: 0.875rem !important;
  font-weight: 1500 !important;
}

.hovered-input input {
  cursor: pointer !important;
}

.hovered-input .v-input__slot:hover {
  background-color: #e0e0e0 !important;
  transition: all 0.3s ease-in-out;
}

.highlighted:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  z-index: 1;
}

.highlighted:hover:before {
  visibility: visible;
}

.image__zoom-icon {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 2000px) {
  .profilAgencyAvatar {
    position: absolute;
    top: 200px;
    z-index: 0;
  }
}

@media screen and (min-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

@media screen and (max-width: 1500px) {
  .profilAgencyAvatar {
    position: absolute;
    top: -50px;
    z-index: 0;
  }
}

.no-padding {
  padding: 0 !important;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.test {
  /*  remove bg color */
  background-color: transparent !important;
}
</style>
